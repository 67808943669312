import { module as m } from 'modujs';
import gsap from 'gsap';

export default class extends m {
    constructor(m) {
        super(m);

        // EVENTS
        this.events = {
            click: {
                close: '_close'
            }
        }
    }

    init() {
    }

    _close() {
        gsap.to(this.el, {
            duration: 1,
            x: '100%',
            ease: 'power4.inOut'
        });
    }
}
