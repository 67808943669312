import { module as m } from 'modujs';
import gsap from 'gsap';

export default class extends m {
    constructor(m) {
        super(m);

        // DOM
        this.openMenuMobile = document.querySelector('.open-mobile');
        this.closeMenuMobile = document.querySelector('#menu-mobile .menu-mobile__top .close');
        this.openFilialesButton = document.querySelector('#menu-mobile .menu-mobile__bottom .select-companies .select-companies__title');
    }

    init() {
        this.openMenuMobile.addEventListener('click', this._openMobile.bind(this));
        this.closeMenuMobile.addEventListener('click', this._closeMobile.bind(this));

        this.openFilialesButton.addEventListener('click', this._openFiliales.bind(this));
    }

    _openMobile() {
        const _tl = gsap.timeline();
        _tl.to('#menu-mobile', {
            duration: 0.6,
            autoAlpha: 1,
            ease: 'power4.out'
        });
    }

    _closeMobile() {
        const _tl = gsap.timeline();
        _tl.to('#menu-mobile', {
            duration: 0.6,
            autoAlpha: 0,
            ease: 'power4.out'
        });
    }

    _openFiliales() {
        const _tl = gsap.timeline();
        if(!this.openFilialesButton.classList.contains('open')) {
            this.openFilialesButton.classList.add('open');
            _tl.to('.select-companies .select-companies__list', {
                duration: 0.6,
                autoAlpha: 1,
                ease: 'power4.out'
            });
        } else {
            this.openFilialesButton.classList.remove('open');
            _tl.to('.select-companies .select-companies__list', {
                duration: 0.6,
                autoAlpha: 0,
                ease: 'power4.out'
            });
        }
    }
}
