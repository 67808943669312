export const StoreSingleton = Object.assign({}, {

    _firstLoad: true,
    _isMobile: false,
    _isPreload: true,

    /**
     * FIRST LOAD
     * @returns {boolean}
     */
    get firstLoad() {
        return this._firstLoad;
    },
    set firstLoad(value) {
        this._firstLoad = value;
    },

    /**
     * IS MOBILE
     * @returns {boolean}
     */
    get isMobile() {
        return this._isMobile;
    },
    set isMobile(value) {
        this._isMobile = value;
    },

    /**
     * IS PRELOAD
     * @returns {boolean}
     */
    get isPreload() {
        return this._isPreload;
    },
    set isPreload(value) {
        this._isPreload = value;
    },
});