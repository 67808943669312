import { module as m } from 'modujs';
import gsap from 'gsap';

export default class extends m {
    constructor(m) {
        super(m);

        // EVENTS
        this.events = {
            click: {
                item: '_open'
            }
        }
    }

    init() {

    }

    /**
     * Open
     * @private
     */
    _open(event) {
        const _target = event.currentTarget;
        const _id = _target.dataset.modalid;
        const _modal = document.querySelector('.' + _id);

        if(_target.classList.contains('has-bio')) {
            console.log('SKDLSKD');
            gsap.to(_modal, {
                duration: 1,
                x: 0,
                ease: 'power4.inOut'
            });
        }
    }
}
