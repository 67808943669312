import { module as m } from 'modujs';
import gsap from 'gsap';

export default class extends m {
    constructor(m) {
        super(m);

        // VAR
        this.currentHead = null;
        this.previous = null;
        this.current = null;
    }

    /**
     * Init
     */
    init() {
        this.$('item').forEach(item => {
            item.querySelector('.accordion-item__head').addEventListener('click', this._openAndClose.bind(this));
        })
    }

    /**
     * Open and close
     * @private
     */
    _openAndClose(event) {
        if(event.currentTarget !== this.currentHead) {

            // Previous
            if(this.current) {
                this.previous = this.current;
                this.previous.classList.remove('open');
                gsap.to(this.previous.querySelector('.accordion-item__content'), {
                    duration: 0.5,
                    height: 0,
                    ease: 'power4.out'
                });
            }

            // Current
            this.currentHead = event.currentTarget;
            this.current = event.currentTarget.parentNode;
            this.current.classList.add('open');
            gsap.to(this.current.querySelector('.accordion-item__content'), {
                duration: 0.5,
                height: this.current.querySelector('.accordion-item__content-inner').clientHeight,
                ease: 'power4.out'
            });
        } else {
            gsap.to(this.current.querySelector('.accordion-item__content'), {
                duration: 0.5,
                height: 0,
                ease: 'power4.out',
                onComplete: () => {
                    this.current.classList.remove('open');
                    this.previous = null;
                    this.currentHead = null;
                    this.current = null;
                }
            });
        }

    }
}
