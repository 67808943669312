import { module as m } from 'modujs';
import SwiperCore, { Navigation } from 'swiper/core';
import {StoreSingleton} from "../../singletons/store";

SwiperCore.use([Navigation]);

export default class extends m {
    constructor(m) {
        super(m);

        // VAR
        this.swiper = null;
        this.margin = 0.025;
        this.gap = window.innerWidth * this.margin;
        this.options = {
            loop: false,
            spaceBetween: this.gap,
            slidesPerView: 3,
            simulateTouch: true,
            breakpoints: {
                320: {
                    slidesPerView: 1,
                },
                1024: {
                    slidesPerView: 3,
                }
            },
            navigation: {
                nextEl: this.el.querySelector('.swiper-navigation__button-next'),
                prevEl: this.el.querySelector('.swiper-navigation__button-prev'),
            },
        }

        // EVENTS
        this._resize = this._resize.bind(this);
    }

    init() {

        // SWIPER
        this.swiper = new SwiperCore(this.$('container')[0], this.options);

        this.swiper.on('slideChange', () => {
            this.$('progress')[0].style.transform = `scaleX(${this.swiper.progress})`;
        });

        // EVENTS
        window.addEventListener('resize', this._resize);
        this._resize();
    }

    /**
     * Resize
     * @private
     */
    _resize() {
        if(StoreSingleton.isMobile)
            this.margin = 0.025;
        else {
            this.margin = 0.025;
        }

        this.gap = window.innerWidth * this.margin;
        this.swiper.params.spaceBetween = this.gap;
    }
}
