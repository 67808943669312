import { module as m } from 'modujs';
import gsap from "gsap";

export default class extends m {
    constructor(m) {
        super(m);

        // VAR
        this.timer = 4000;
        this.index = 0;
        this.previous = null;
        this.current = this.$('item')[0];
    }

    init() {
        setTimeout(() => {
            this.changeFace();
        }, this.timer);
    }

    /**
     * Change face
     */
    changeFace() {
        if(this.index < this.$('item').length - 1)
            this.index++;
        else
            this.index = 0;

        this.previous = this.current;
        this.current = this.$('item')[this.index];

        const _t = gsap.timeline({
            onComplete: () => {
                setTimeout(() => {
                    this.changeFace();
                }, this.timer);
            }
        });

        _t.set(this.current, {
            x: -50,
            opacity: 0
        });
        _t.to(this.previous, {
            duration: 1.2,
            x: 50,
            opacity: 0,
            ease: 'power4.inOut'
        });
        _t.to(this.current, {
            duration: 1.2,
            x: 0,
            opacity: 1,
            ease: 'power4.out'
        }, 0.6);
    }
}
